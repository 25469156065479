import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { LoginFormPage, ProConfigProvider, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { Tabs, theme } from 'antd';
import React, { useState } from 'react';
import { request } from '../../utils';
import { useNavigate } from 'react-router-dom';

type LoginType = 'account';

const Page = () => {
  const [loginType, setLoginType] = useState<LoginType>('account');
  const { token } = theme.useToken();
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundColor: 'white',
        height: '100vh',
      }}
    >
      <LoginFormPage
        onFinish={async (formData) => {
          const req = await request<{ data: any }>({
            method: 'POST',
            url: '/quan/auth/oauth/token',
            headers: { Authorization: 'Basic Ym9xdWFuLW1hbmFnZW1lbnQ6MTIzNDU2' },
            data: {
              grantType: 'BOQUAN_PASSWORD',
              passwordModel: { ...formData },
            },
          });
          if (req?.data?.accessToken) {
            window.localStorage.setItem('Authorization', `${req.data.bearer} ${req?.data?.accessToken}`);
            window.location.assign('/pbb-pc-anchorcircle/home/index');
          }
        }}
        // backgroundImageUrl="https://mdn.alipayobjects.com/huamei_gcee1x/afts/img/A*y0ZTS6WLwvgAAAAAAAAAAAAADml6AQ/fmt.webp"
        // logo="https://github.githubassets.com/favicons/favicon.png"
        backgroundVideoUrl="https://gw.alipayobjects.com/v/huamei_gcee1x/afts/video/jXRBRK_VAwoAAAAAAAAAAAAAK4eUAQBr"
        title="播圈儿"
        containerStyle={{
          backgroundColor: 'rgba(0, 0, 0,0.65)',
          backdropFilter: 'blur(4px)',
        }}
        onClick={() => {
          console.log('22');
        }}
      >
        <Tabs
          activeKey={loginType}
          onChange={(activeKey) => setLoginType(activeKey as LoginType)}
        >
          <Tabs.TabPane
            key="account"
            tab="账号密码登录"
          />
        </Tabs>
        {loginType === 'account' && (
          <>
            <ProFormText
              name="username"
              fieldProps={{
                size: 'large',
                prefix: (
                  <UserOutlined
                    style={{ color: token.colorText }}
                    className="prefixIcon"
                  />
                ),
              }}
              placeholder="请输入用户名"
              rules={[
                {
                  required: true,
                  message: '请输入用户名!',
                },
              ]}
            />
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: 'large',
                prefix: (
                  <LockOutlined
                    style={{ color: token.colorText }}
                    className="prefixIcon"
                  />
                ),
              }}
              placeholder="请输入密码"
              rules={[
                {
                  required: true,
                  message: '请输入密码！',
                },
              ]}
            />
          </>
        )}
        <div style={{ marginBlockEnd: 24 }}>
          {/*<ProFormCheckbox*/}
          {/*  noStyle*/}
          {/*  name="autoLogin"*/}
          {/*>*/}
          {/*  自动登录*/}
          {/*</ProFormCheckbox>*/}
          {/*<a style={{ float: 'right' }}>忘记密码</a>*/}
        </div>
      </LoginFormPage>
    </div>
  );
};

export default () => (
  <ProConfigProvider dark>
    <Page />
  </ProConfigProvider>
);
